import { pushToDataLayer } from "@shared/Analytics/data/data-layer.facade";
import { attachClickEventListener } from "@shared/Utils/analytics-utils";

export type GA4PhoneClickEvent = {
  event: "phone_number_click";
};

const CUSTOMER_SERVICE_PHONE_TRACKING_IDS = ["Kundeservice telefonnummer", "customer-service-phone-number"];

export const bootstrapPhoneClickAnalytics = () => {
  CUSTOMER_SERVICE_PHONE_TRACKING_IDS.forEach((dataId) => {
    const element = document.querySelector(`[data-id="${dataId}"]`);
    if (!element) return;

    attachClickEventListener(element, () => {
      pushToDataLayer({
        event: "phone_number_click",
      } as GA4PhoneClickEvent);
    });
  });
};
