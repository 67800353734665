import { normalizeWhitespace } from "@shared/Utils";
import { pushToDataLayer } from "../data/data-layer.facade";
import { attachClickEventListener } from "@shared/Utils/analytics-utils";

export type GA4FrontPageEvent = {
  event: "frontpage_tracking";
  element_category: string;
  element_cta?: string;
  element_description: string;
  index: number;
};

const SECTION_DATA_IDS = ["forside-banner", "hurtig-valg", "Snarveier", "aktuelt", "Mer data"];

const extractElementDescription = (targetElement: HTMLElement): string => {
  const descriptionTextContent = targetElement.closest<HTMLElement>("a")?.textContent;
  const altImageValue = targetElement.getAttribute("alt");

  return normalizeWhitespace(descriptionTextContent || altImageValue || "");
};

export const bootstrapFrontPageAnalytics = () => {
  SECTION_DATA_IDS.forEach((sectionDataId, index) => {
    const elements = document.querySelectorAll(`[data-id='${sectionDataId}'] a`);

    attachClickEventListener(elements, (e) => {
      const target = e.target as HTMLElement;
      const cta = target.closest<HTMLElement>("[data-id]")!.getAttribute("data-id");
      const description = extractElementDescription(target);

      const event: GA4FrontPageEvent = {
        event: "frontpage_tracking",
        element_category: sectionDataId,
        element_cta: cta || "",
        element_description: description,
        index: index,
      };

      pushToDataLayer(event);
    });
  });
};
