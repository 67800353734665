import { pushToDataLayer } from "../data/data-layer.facade";
import { attachClickEventListener, getBusinessUnit } from "@shared/Utils/analytics-utils";

export type BaseGA4MenuEvent = {
  event: "menu";
  element_category: MenuAnalyticsCategory;
  element_cta?: string;
  business_unit: "B2B" | "B2C";
};

type MenuAnalyticsCategory =
  | "main_menu"
  | "side_menu"
  | "sub_menu"
  | "sub_menu_head"
  | "menu_burger"
  | "footer_menu"
  | "footer_menu_head"
  | "search_bar"
  | "logged_in_dropdown_box";

type GA4MenuTrackingItem = {
  selector: string;
  category: MenuAnalyticsCategory;
  mode?: "data-id" | "query-selector";
};

const GA4_MENU_TRACKING_ITEMS: GA4MenuTrackingItem[] = [
  { selector: "btn-customer-login", category: "logged_in_dropdown_box", mode: "data-id" },
  { selector: "main-menu-logo", category: "side_menu", mode: "data-id" },
  { selector: "Handlekurv", category: "side_menu", mode: "data-id" },
  { selector: "Bedrift", category: "side_menu", mode: "data-id" },
  { selector: "Privat", category: "side_menu", mode: "data-id" },
  { selector: "footer ul .list li", category: "footer_menu" },
  { selector: "[data-id='search-bar'] button[type='submit']", category: "search_bar" },
  { selector: ".footer__social [data-id]", category: "footer_menu" },
  { selector: "[data-id='sub-menu-head']", category: "main_menu" },
  { selector: "[data-id='footer-menu-head']", category: "footer_menu_head" },
  { selector: "sub-menu-head", category: "sub_menu_head" },
  { selector: "[data-id='sub-menu']", category: "sub_menu" },
];

const sendGA4MenuEvent = (category: MenuAnalyticsCategory, cta: string) => {
  const event: BaseGA4MenuEvent = {
    event: "menu",
    element_category: category,
    element_cta: cta,
    business_unit: getBusinessUnit(),
  };

  pushToDataLayer(event);
};

export const bootstrapMenuAnalytics = (): void => {
  GA4_MENU_TRACKING_ITEMS.forEach(({ selector, category, mode }) => {
    const items =
      mode === "data-id" ? document.querySelector(`[data-id='${selector}']`) : document.querySelectorAll(selector);

    if (!items) {
      console.warn(`No elements found with selector: ${selector}`);
      return;
    }

    attachClickEventListener(items, (e) => {
      const cta = (e.target as HTMLElement).closest<HTMLElement>("[data-id")?.getAttribute("data-id") || "unknown";
      sendGA4MenuEvent(category, cta);
    });
  });
};
