﻿import { debounce } from "@shared/Utils";

// This just works for small screens under 375px
// We need a better calculation for bigger area
// used this page as Inspiration: https://css-tricks.com/scaled-proportional-blocks-with-css-and-javascript/

export const bootstrapContentResizeScale = (): void => {
  const resize = (el: HTMLElement): void => {
    const width = window.innerWidth;
    if (width > 375) {
      el.style.transform = "";
      return;
    }

    const scale = width / 375;

    el.style.transform = `scale(${scale})`;
  };

  const scaleEl = document.querySelectorAll(".content-resize-scale");
  scaleEl.forEach((el: HTMLElement) => {
    resize(el);

    window.addEventListener(
      "resize",
      debounce((): void => resize(el), 10),
      { passive: true },
    );
  });
};
